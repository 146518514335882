import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

import { VueSignalR } from "@dreamonkey/vue-signalr";
//import { HubConnectionBuilder } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
//import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
//import JlDatatable from "jl-datatable";
import "@/core/plugins/prismjs";
import VueAwesomePaginate from "vue-awesome-paginate";

const app = createApp(App);

const connection = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_HUB_URL ?? "")
  .build();

app.use(VueSignalR, {
  connection,
  failFn: () => {
    console.log("signal r error");
  },
  autoOffInsideComponentScope: true,
});
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueAwesomePaginate);
ApiService.init(app);
//initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
//app.component("jl-datatable", JlDatatable);
app.mount("#app");
